<template>
  <v-container fluid class="terms-container pa-0">
    <v-row no-gutters>
      <!-- Main Content -->
      <v-col cols="12" lg="9" class="content-area">
        <v-container>
          <v-card class="mt-4 mb-4">
            <v-card-title class="text-center text-h4 pt-6">
              Términos y Condiciones
            </v-card-title>

            <!-- Content -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div
                      v-for="(section, index) in sections"
                      :key="section.id"
                      :id="section.id"
                      class="mb-6 section-content"
                    >
                      <h2 class="text-h6 font-weight-bold mb-3">
                        {{ section.title }}
                      </h2>
                      <p class="text-body-1 text-justified">
                        {{ section.content }}
                      </p>
                      <v-divider
                        v-if="index < sections.length - 1"
                        class="mt-4"
                      ></v-divider>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="justify-center pb-6">
              <v-btn color="primary" variant="elevated" size="large" to="/">
                Volver al Inicio
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>

      <!-- Fixed Right Index -->
      <v-col lg="3" class="d-none d-lg-block">
        <v-card class="fixed-index" variant="outlined" elevation="2">
          <v-card-title class="text-h6">Índice</v-card-title>
          <v-card-text class="pa-0">
            <v-list density="compact" nav>
              <v-list-item
                v-for="section in sections"
                :key="section.id"
                :title="section.title"
                @click="scrollToSection(section.id)"
                class="cursor-pointer"
                :class="{ 'active-section': false }"
                rounded="lg"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="section.title"></v-list-item-title>
                </v-list-item-content>
                
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
export default {
  data() {
    return {
      scrollToSection: (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      },
      sections: [
        {
          id: "propiedad",
          title: "Propiedad Intelectual",
          content: `El Servicio y todos los materiales incluidos o transferidos,
					incluyendo, sin limitación, software, imágenes, texto, gráficos,
					logotipos, patentes, marcas registradas, marcas de servicio,
					derechos de autor, fotografías, audio, música y todos los Derechos
					de Propiedad Intelectual relacionados con ellos, son la propiedad
					exclusiva de TRACK AND RACER. Tal como consta en el certificado
					de propiedad intelectual Nro. XXX de fecha XX emitido por el
					Servicio Autónomo de Propiedad Intelectual venezolano.
					Nosotros somos los únicos propietarios de la marca registrada
					“TAR.LA" y del logotipo de "TAR.LA"
					. Cualquier uso no autorizado
					de la marca registrada " TAR.LA " y del logotipo de "TAR.LA" puede
					resultar en un juicio.
					Con relación a www.TAR.LA o cualquier otro subdominio es
					nuestro localizador uniforme de recursos, no podrá hacerse ningún
					uso no autorizado de esta dirección URL en cualquier otro Sitio o
					plataforma digital sin nuestro consentimiento previo por escrito. Los
					enlaces al Sitio y a cualquiera de las páginas en el mismo no
					pueden incluirse en ningún otro sitio sin nuestro consentimiento
					previo por escrito.
					Somos los dueños o el legítimo titular de los derechos de los
					sistemas de tecnología utilizados dentro del Sitio.
					El contenido y la estructura de las páginas del Sitio están sujetos a
					copyright © y derechos sobre base de datos en nombre de
					“TAR.LA"
					. Todos los derechos reservados.
					El copyright en este Sitio incluye a todos los textos, gráficos,
					códigos, archivos y enlaces que nos pertenecen y el Sitio no puede
					ser reproducido, transmitido o almacenado en su totalidad o en
					parte sin nuestro consentimiento por escrito. Su registro y uso del
					Sitio no te confiere ningún derecho de ningún tipo sobre la
					propiedad intelectual contenida en el Sitio.
					Aceptas no utilizar ningún dispositivo automático o manual para
					monitorear nuestras páginas web o cualquier contenido en ellas.
					Cualquier uso o reproducción no autorizados pueden ser sujetos a
					juicios.
					Salvo que se indique explícitamente en este documento, no se
					considerará que nada en estos Términos crea una licencia en o bajo
					ninguno de dichos Derechos de Propiedad Intelectual, y el usuario
					acepta no vender, licenciar, alquilar, modificar, distribuir, copiar,
					reproducir, transmitir, exhibir públicamente, realizar públicamente,
					publicar, adaptar, editar o crear trabajos derivados de los mismos.`,
        },
        {
          id: "privacidad",
          title: "Privacidad",
          content: `Su uso de este sitio web está también sujeto a nuestra Política de Privacidad. Por favor, revise nuestra Política de Privacidad, que también gobierna su visita a nuestro sitio web, para entender nuestras prácticas.`,
        },
        {
          id: "privacidad",
          title: "Privacidad",
          content: `Su uso de este sitio web está también sujeto a nuestra Política de Privacidad. Por favor, revise nuestra Política de Privacidad, que también gobierna su visita a nuestro sitio web, para entender nuestras prácticas.`,
        },
        {
          id: "responsabilidad",
          title: "Limitación de Responsabilidad",
          content: `En ningún caso la empresa será responsable por cualquier daño directo, indirecto, incidental, consecuente, especial o ejemplar, incluyendo pero no limitado a, daños por pérdida de beneficios, buena voluntad, uso, datos u otras pérdidas intangibles.`,
        },
        {
          id: "enlaces",
          title: "Enlaces a Terceros",
          content: `Nuestro sitio web puede contener enlaces a sitios web de terceros. Estos enlaces son proporcionados únicamente para su conveniencia. No tenemos control sobre el contenido de estos sitios web y no aceptamos responsabilidad por ellos o por cualquier pérdida o daño que pueda surgir de su uso.`,
        },
        {
          id: "intro",
          title: "Términos y Condiciones de Uso",
          content: `Al acceder y utilizar este sitio web, usted acepta estar sujeto a los siguientes términos y condiciones de uso. Si no está de acuerdo con estos términos, por favor no utilice este sitio.`,
        },
        {
          id: "uso",
          title: "Uso del Sitio",
          content: `El contenido de este sitio web es únicamente para su información general y uso personal. Está sujeto a cambios sin previo aviso.`,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.terms-container {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.content-area {
  max-width: 100%;
}

.fixed-index {
  position: fixed;
  top: 6rem;
  width: calc(25% - 2rem);
  margin-right: 1rem;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.section-content {
  scroll-margin-top: 2rem;
}

.text-justified {
  text-align: justify;
}

:deep(.v-list-item) {
  min-height: 40px !important;
  margin-bottom: 4px;
}

:deep(.v-list-item:hover) {
  background-color: rgba(var(--v-theme-primary), 0.1);
}

:deep(.v-list-item.active-section) {
  background-color: rgba(var(--v-theme-primary), 0.1);
  font-weight: bold;
}

@media (max-width: 1263px) {
  .content-area {
    max-width: 100%;
  }
}
</style>
